import React from "react";
import SummerKindnessSeriesLayout from "@src/layouts/SummerKindnessSeriesLayout";
import SEO from "@src/components/SEO";
import { graphql, useStaticQuery } from "gatsby";
import window from "global/window";

function SummerKindnessSeriesWeek9() {
  const data = useStaticQuery(graphql`
    {
      headerImage: file(name: { eq: "leadership500x387" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 640, quality: 90, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      activity1: file(name: { eq: "mojo-hero" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
    }
  `);

  const { headerImage, activity1 } = data;
  return (
    <>
      <SEO
        title="The Summer Kindness Series - Week 9"
        description="A simple, weekly series for families to build new habits at home and inspire compassionate kids!"
        image="https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "ClassDojo's Summer Kindness Series - Week 9",
          description:
            "A simple, weekly series for families to build new habits at home and inspire compassionate kids!",
          image: "https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg",
        }}
      />
      <SummerKindnessSeriesLayout
        header="Kindness Leadership"
        headerSub="Kindness Habit 9:"
        headerText={
          <>
            Make a difference for others,
            <br />
            no matter how small
          </>
        }
        headerImage={headerImage}
        whatisIt="You know that incredible “aha!” moment when a hero discovers they have superpowers? We get goosebumps just thinking about it. When kids realize they have the ability to make a difference for others, its their moment to become a Changemaker! When Changemakers see someone in need, they want to help. When they recognize a social problem, they’re driven to find solutions. Aha! What better superpower could there be?"
        whyIsItImportant="Kids need help to understand the power already within them! They don’t need a utility belt or a Spidey sense to be, well, SUPER. Every act of kindness creates a positive change for others, no matter how small. Plus, when kids take action to be a Changemaker, it helps them be happier, healthier and feel better about themselves. Ready to transform kind thoughts into kind actions? Cape optional :)"
        habbits={[
          {
            header: "Encourage a Growth Mindset:",
            text: "Those that believe they can, do! From Reading Emotions to Kindness Leadership, each kindness habit can be strengthened with practice, just like a muscle!",
          },
          {
            header: "Encourage Practice:",
            text: "Provide simple ways for kids to start leading. Put them in charge of your family's donation box, or offer to match a small fundraising effort for a charity of their choice!",
          },
          {
            header: "Act Local:",
            text: "Passion is one of the best motivators to make a difference. Help kids find a local cause they really care about, so they can witness the change that they are making!",
          },
          {
            header: "Make Their Impact Known:",
            text: "“What did the person do when you helped? How do you think he/she felt?” Point out the impact of their kind actions. Remind them their efforts are making a difference!",
          },
        ]}
        activitiesText="Ready to be a Changemaker?"
        activities={[
          {
            icon: activity1,
            header: "Be Mojo's Hero!",
            text: "Submit your idea to be a Changemaker and Mojo may help you make it a reality!",
            onClick: () => {
              window.location.href = "https://www.surveymonkey.com/r/MojosHeroes";
            },
          },
        ]}
        lastPage={true}
      />
    </>
  );
}

export default SummerKindnessSeriesWeek9;
